import axionIns from "../axios";

const getServiceProviders = async (page, limit, search) => {
  return axionIns
    .get(`admin/profile/providers?page=${page ?? ''}&limit=${limit ?? ''}&search=${search ?? ''}`)
    .then((response) => {
      return response.data;
    });
};

export default {
  getServiceProviders,
};
