import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { RoutesPaths } from "../Router/constant";


export default function Sidebar() {

    const dashboardPath = useLocation(RoutesPaths.DASHBOARD);
    const userManagerPath = useLocation(RoutesPaths.USER_MANAGER);
    const serviceProviderPath = useLocation(RoutesPaths.SERVICE_PROVIDER)
    const servicesPath = useLocation(RoutesPaths.SERVICES)
    const revenueTrackingPath = useLocation(RoutesPaths.REVENUE_TRACKING)
    const notificationCenterPath = useLocation(RoutesPaths.NOTIFICATION_CENTER)
    const couponPath = useLocation(RoutesPaths.COUPON)
    const bannersPath = useLocation(RoutesPaths.BANNERS)
    const questionPath = useLocation(RoutesPaths.QUESTION)

    return (
        <>
            <div
                id="kt_app_sidebar"
                className="app-sidebar flex-column"
                data-kt-drawer="true"
                data-kt-drawer-name="app-sidebar"
                data-kt-drawer-activate="{default: true, lg: false}"
                data-kt-drawer-overlay="true"
                data-kt-drawer-width="250px"
                data-kt-drawer-direction="start"
                data-kt-drawer-toggle="#kt_app_sidebar_mobile_toggle"
            >
                <div
                    className="app-sidebar-header d-flex flex-stack d-none d-lg-flex pt-8 pb-2"
                    id="kt_app_sidebar_header"
                >
                    {/*begin::Logo*/}
                    <a href="index.html" className="app-sidebar-logo">
                        <img
                            alt="Logo"
                            src="assets/img/express-job-logo.svg"
                            className="d-none d-sm-inline app-sidebar-logo-default theme-light-show w-75"
                        />
                        <img
                            alt="Logo"
                            src="assets/img/express-job-logo.svg"
                            className="h-lg-25px theme-dark-show  w-75"
                        />
                    </a>
                    {/*end::Logo*/}
                </div>
                {/*begin::Navs*/}
                <div
                    className="app-sidebar-navs flex-column-fluid py-6"
                    id="kt_app_sidebar_navs"
                >
                    <div
                        id="kt_app_sidebar_navs_wrappers"
                        className="app-sidebar-wrapper hover-scroll-y my-2"
                        data-kt-scroll="true"
                        data-kt-scroll-activate="true"
                        data-kt-scroll-height="auto"
                        data-kt-scroll-dependencies="#kt_app_sidebar_header"
                        data-kt-scroll-wrappers="#kt_app_sidebar_navs"
                        data-kt-scroll-offset="5px"
                    >
                        {/*begin::Sidebar menu*/}
                        <div
                            id="#kt_app_sidebar_menu"
                            data-kt-menu="true"
                            data-kt-menu-expand="false"
                            className="app-sidebar-menu-primary menu menu-column menu-rounded menu-sub-indention menu-state-bullet-primary"
                        >
                            {/*begin:Menu item*/}
                            <NavLink
                                active={dashboardPath?.isExact}
                                to={RoutesPaths.DASHBOARD}
                                className="menu-item here show menu-accordion"
                            >
                                {/*begin:Menu link*/}
                                <span className="menu-link">
                                    <span className="menu-icon">
                                        <i className="ki-outline ki-home-2 fs-2" />
                                    </span>
                                    <span className="menu-title">Dashboards</span>
                                </span>
                                {/*end:Menu link*/}
                            </NavLink>
                            {/*end:Menu item*/}
                            {/*begin:Menu item*/}
                            
                            <NavLink
                                active={userManagerPath?.isExact}
                                to={RoutesPaths.USER_MANAGER}
                                className="menu-item here show menu-accordion"
                            >
                                {/*begin:Menu link*/}
                                <span className="menu-link">
                                    <span className="menu-icon">
                                        <i className="ki-outline ki-gift fs-2" />
                                    </span>
                                    <span className="menu-title">User Manager</span>
                                </span>
                                {/*end:Menu link*/}
                            </NavLink>
                            {/*end:Menu item*/}

                            {/*begin:Menu item*/}
                            <NavLink
                                active={servicesPath?.isExact}
                                to={RoutesPaths.CATEGORY}
                                className="menu-item here show menu-accordion"
                            >
                                {/*begin:Menu link*/}
                                <span className="menu-link">
                                    <span className="menu-icon">
                                        <i className="ki-outline ki-abstract-35 fs-2" />
                                    </span>
                                    <span className="menu-title">Category</span>
                                </span>
                                {/*end:Menu link*/}
                            </NavLink>
                            {/*end:Menu item*/}
                            
                            {/*begin:Menu item*/}
                            <NavLink
                                active={serviceProviderPath?.isExact}
                                to={RoutesPaths.SERVICE_PROVIDER}
                                className="menu-item here show menu-accordion"
                            >
                                {/*begin:Menu link*/}
                                <span className="menu-link">
                                    <span className="menu-icon">
                                        <i className="ki-outline ki-abstract-26 fs-2" />
                                    </span>
                                    <span className="menu-title">Service Provider</span>
                                </span>
                                {/*end:Menu link*/}
                            </NavLink>
                            {/*end:Menu item*/}
                            {/*begin:Menu item*/}
                            <NavLink
                                active={servicesPath?.isExact}
                                to={RoutesPaths.SERVICES}
                                className="menu-item here show menu-accordion"
                            >
                                {/*begin:Menu link*/}
                                <span className="menu-link">
                                    <span className="menu-icon">
                                        <i className="ki-outline ki-abstract-35 fs-2" />
                                    </span>
                                    <span className="menu-title">Services</span>
                                </span>
                                {/*end:Menu link*/}
                            </NavLink>
                            
                            {/*begin:Menu item*/}
                            <NavLink
                                active={revenueTrackingPath?.isExact}
                                to={RoutesPaths.REVENUE_TRACKING}
                                className="menu-item here show menu-accordion"
                            >
                                {/*begin:Menu link*/}
                                <span className="menu-link">
                                    <span className="menu-icon">
                                        <i className="ki-outline ki-briefcase fs-2" />
                                    </span>
                                    <span className="menu-title">Revenue Tracking</span>
                                </span>
                                {/*end:Menu link*/}
                            </NavLink>
                            {/*end:Menu item*/}
                            {/*begin:Menu item*/}
                            <NavLink
                                active={notificationCenterPath?.isExact}
                                to={RoutesPaths.NOTIFICATION_CENTER}
                                className="menu-item here show menu-accordion"
                            >
                                {/*begin:Menu link*/}
                                <span className="menu-link">
                                    <span className="menu-icon">
                                        <i className="ki-outline ki-briefcase fs-2" />
                                    </span>
                                    <span className="menu-title">Notification Center</span>
                                </span>
                                {/*end:Menu link*/}
                            </NavLink>
                            {/*end:Menu item*/}
                            {/*begin:Menu item*/}
                            <NavLink

                                active={couponPath?.isExact}
                                to={RoutesPaths.COUPON}
                                className="menu-item here show menu-accordion"
                            >
                                {/*begin:Menu link*/}
                                <span className="menu-link">
                                    <span className="menu-icon">
                                        <i className="ki-outline ki-briefcase fs-2" />
                                    </span>
                                    <span className="menu-title">Create Coupon</span>
                                </span>
                                {/*end:Menu link*/}
                            </NavLink>
                            {/*end:Menu item*/}

                            {/*begin:Menu item*/}
                            <NavLink

                                active={bannersPath?.isExact}
                                to={RoutesPaths.BANNERS}
                                className="menu-item here show menu-accordion"
                            >
                                {/*begin:Menu link*/}
                                <span className="menu-link">
                                    <span className="menu-icon">
                                        <i className="ki-outline ki-briefcase fs-2" />
                                    </span>
                                    <span className="menu-title">Banners</span>
                                </span>
                                {/*end:Menu link*/}
                            </NavLink>
                            {/*end:Menu item*/}

                            <NavLink
                                active={questionPath?.isExact}
                                to={RoutesPaths.QUESTION}
                                className="menu-item here show menu-accordion"
                            >
                                {/*begin:Menu link*/}
                                <span className="menu-link">
                                    <span className="menu-icon">
                                        <i className="ki-outline ki-abstract-35 fs-2" />
                                    </span>
                                    <span className="menu-title">Question & Answer</span>
                                </span>
                                {/*end:Menu link*/}
                            </NavLink>
                        </div>
                        {/*end::Sidebar menu*/}
                    </div>
                </div>
                {/*end::Navs*/}
            </div></>
    )
}