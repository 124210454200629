import { NavLink } from "react-router-dom";
import { RoutesPaths } from "../../Router/constant";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getServiceProvidersAction } from "../../Action/serviceProvider";
import { getServicesAction } from "../../Action/Services";

export default function Dashboard() {
  const dispatch = useDispatch();
  const { categoryList, count } = useSelector((state) => state.services);
  const providerData = useSelector((state) => state.serviceProvider);

  useEffect(() => {
    dispatch(getServiceProvidersAction());
    dispatch(getServicesAction());
  }, []);

  return (
    <>
      <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
        <div className="d-flex flex-column flex-column-fluid">
          <div id="kt_app_content" className="app-content flex-column-fluid">
            <div
              id="kt_app_content_container"
              className="app-container container-fluid"
            >
              <>
                <div className="row g-5 g-xl-10 mb-xl-10">
                  <div className="col-md-4 col-lg-4 col-xl-4 col-xxl-4 mb-md-5 mb-xl-10">
                    <div
                      className="card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-center border-0 h-md-100 mb-5 mb-xl-10"
                      style={{ backgroundColor: "#F1416C" }}
                    >
                      <div className="card-header pt-5">
                        <div className="card-title d-flex flex-column">
                          <span className="fs-2hx fw-bold text-white me-2 lh-1 ls-n2">
                            25,000
                          </span>
                          <span className="text-white pt-1 fw-semibold fs-6">
                            Registered Users
                          </span>
                        </div>
                      </div>
                      <div className="card-footer d-flex justify-content-end pt-0 mt-20 mb-0 pb-0">
                        <NavLink
                          className="btn btn-sm btn-light flex-shrink-0"
                          to={RoutesPaths.OVERVIEW_USER_MANAGER}
                        >
                          View All
                        </NavLink>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-lg-4 col-xl-4 col-xxl-4 mb-md-5 mb-xl-10">
                    <div
                      className="card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-center border-0 h-md-100 mb-5 mb-xl-10"
                      style={{ backgroundColor: "#F1416C" }}
                    >
                      <div className="card-header pt-5">
                        <div className="card-title d-flex flex-column">
                          <span className="fs-2hx fw-bold text-white me-2 lh-1 ls-n2">
                            {providerData?.count}
                          </span>
                          <span className="text-white pt-1 fw-semibold fs-6">
                            Service Providers
                          </span>
                        </div>
                      </div>
                      <div className="card-footer d-flex justify-content-end pt-0 mt-20 mb-0 pb-0">
                        <NavLink
                          className="btn btn-sm btn-light flex-shrink-0"
                          to={RoutesPaths.SERVICE_PROVIDER}
                        >
                          View All
                        </NavLink>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-lg-4 col-xl-4 col-xxl-4 mb-md-5 mb-xl-10">
                    <div
                      className="card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-center border-0 h-md-100 mb-5 mb-xl-10"
                      style={{ backgroundColor: "#F1416C" }}
                    >
                      <div className="card-header pt-5">
                        <div className="card-title d-flex flex-column">
                          <span className="fs-2hx fw-bold text-white me-2 lh-1 ls-n2">
                            {count}
                          </span>
                          <span className="text-white pt-1 fw-semibold fs-6">
                            Service Available
                          </span>
                        </div>
                      </div>
                      <div className="card-footer d-flex justify-content-end pt-0 mt-20 mb-0 pb-0">
                        <NavLink
                          className="btn btn-sm btn-light flex-shrink-0"
                          to={RoutesPaths.SERVICES}
                        >
                          View All
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </>

              <div className="row g-5 g-xl-10 g-xl-10">
                <div className="col-md-5">
                  <div className="card card-xl-stretch mb-xl-8">
                    {/*begin::Header*/}
                    <div className="card-header border-0 bg-info py-5">
                      <h3 className="card-title fw-bold text-white">
                        Revenue Overview
                      </h3>
                      <div className="card-toolbar">
                        {/*begin::Menu*/}
                        <button
                          type="button"
                          className="btn btn-sm btn-icon btn-color-white btn-active-white btn-active-color- border-0 me-n3"
                          data-kt-menu-trigger="click"
                          data-kt-menu-placement="bottom-end"
                        >
                          <i className="ki-duotone ki-category fs-6">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                            <span className="path4" />
                          </i>{" "}
                        </button>
                        {/*begin::Menu 3*/}
                        <div
                          className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3"
                          data-kt-menu="true"
                        >
                          {/*begin::Heading*/}
                          <div className="menu-item px-3">
                            <div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">
                              Payments
                            </div>
                          </div>
                          {/*end::Heading*/}
                          {/*begin::Menu item*/}
                          <div className="menu-item px-3">
                            <a href="#" className="menu-link px-3">
                              Create Invoice
                            </a>
                          </div>
                          {/*end::Menu item*/}
                          {/*begin::Menu item*/}
                          <div className="menu-item px-3">
                            <a href="#" className="menu-link flex-stack px-3">
                              Create Payment
                              <span
                                className="ms-2"
                                data-bs-toggle="tooltip"
                                aria-label="Specify a target name for future usage and reference"
                                data-bs-original-title="Specify a target name for future usage and reference"
                                data-kt-initialized={1}
                              >
                                <i className="ki-duotone ki-information fs-6">
                                  <span className="path1" />
                                  <span className="path2" />
                                  <span className="path3" />
                                </i>{" "}
                              </span>
                            </a>
                          </div>
                          {/*end::Menu item*/}
                          {/*begin::Menu item*/}
                          <div className="menu-item px-3">
                            <a href="#" className="menu-link px-3">
                              Generate Bill
                            </a>
                          </div>
                          {/*end::Menu item*/}
                          {/*begin::Menu item*/}
                          <div
                            className="menu-item px-3"
                            data-kt-menu-trigger="hover"
                            data-kt-menu-placement="right-end"
                          >
                            <a href="#" className="menu-link px-3">
                              <span className="menu-title">Subscription</span>
                              <span className="menu-arrow" />
                            </a>
                            {/*begin::Menu sub*/}
                            <div className="menu-sub menu-sub-dropdown w-175px py-4">
                              {/*begin::Menu item*/}
                              <div className="menu-item px-3">
                                <a href="#" className="menu-link px-3">
                                  Plans
                                </a>
                              </div>
                              {/*end::Menu item*/}
                              {/*begin::Menu item*/}
                              <div className="menu-item px-3">
                                <a href="#" className="menu-link px-3">
                                  Billing
                                </a>
                              </div>
                              {/*end::Menu item*/}
                              {/*begin::Menu item*/}
                              <div className="menu-item px-3">
                                <a href="#" className="menu-link px-3">
                                  Statements
                                </a>
                              </div>
                              {/*end::Menu item*/}
                              {/*begin::Menu separator*/}
                              <div className="separator my-2" />
                              {/*end::Menu separator*/}
                              {/*begin::Menu item*/}
                              <div className="menu-item px-3">
                                <div className="menu-content px-3">
                                  {/*begin::Switch*/}
                                  <label className="form-check form-switch form-check-custom form-check-solid">
                                    {/*begin::Input*/}
                                    <input
                                      className="form-check-input w-30px h-20px"
                                      type="checkbox"
                                      defaultValue={1}
                                      defaultChecked="checked"
                                      name="notifications"
                                    />
                                    {/*end::Input*/}
                                    {/*end::Label*/}
                                    <span className="form-check-label text-muted fs-6">
                                      Recuring
                                    </span>
                                    {/*end::Label*/}
                                  </label>
                                  {/*end::Switch*/}
                                </div>
                              </div>
                              {/*end::Menu item*/}
                            </div>
                            {/*end::Menu sub*/}
                          </div>
                          {/*end::Menu item*/}
                          {/*begin::Menu item*/}
                          <div className="menu-item px-3 my-1">
                            <a href="#" className="menu-link px-3">
                              Settings
                            </a>
                          </div>
                          {/*end::Menu item*/}
                        </div>
                        {/*end::Menu 3*/}
                        {/*end::Menu*/}
                      </div>
                    </div>
                    {/*end::Header*/}
                    {/*begin::Body*/}
                    <div className="card-body p-0">
                      {/*begin::Chart*/}
                      <div
                        className="mixed-widget-12-chart card-rounded-bottom bg-info"
                        data-kt-color="info"
                        style={{ height: 250, minHeight: 250 }}
                      >
                        <div
                          id="apexchartsfspihy3xf"
                          className="apexcharts-canvas apexchartsfspihy3xf apexcharts-theme-light"
                          style={{ width: 400, height: 250 }}
                        >
                          <svg
                            id="SvgjsSvg3724"
                            width={400}
                            height={250}
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            className="apexcharts-svg"
                            transform="translate(0, 0)"
                            style={{ background: "transparent" }}
                          >
                            <foreignObject x={0} y={0} width={400} height={250}>
                              <div
                                className="apexcharts-legend"
                                xmlns="http://www.w3.org/1999/xhtml"
                                style={{ maxHeight: 125 }}
                              />
                            </foreignObject>
                            <g
                              id="SvgjsG3794"
                              className="apexcharts-yaxis"
                              rel={0}
                              transform="translate(-18, 0)"
                            />
                            <g
                              id="SvgjsG3726"
                              className="apexcharts-inner apexcharts-graphical"
                              transform="translate(55, 0)"
                            >
                              <defs id="SvgjsDefs3725">
                                <linearGradient
                                  id="SvgjsLinearGradient3728"
                                  x1={0}
                                  y1={0}
                                  x2={0}
                                  y2={1}
                                >
                                  <stop
                                    id="SvgjsStop3729"
                                    stopOpacity="0.4"
                                    stopColor="rgba(216,227,240,0.4)"
                                    offset={0}
                                  />
                                  <stop
                                    id="SvgjsStop3730"
                                    stopOpacity="0.5"
                                    stopColor="rgba(190,209,230,0.5)"
                                    offset={1}
                                  />
                                  <stop
                                    id="SvgjsStop3731"
                                    stopOpacity="0.5"
                                    stopColor="rgba(190,209,230,0.5)"
                                    offset={1}
                                  />
                                </linearGradient>
                                <clipPath id="gridRectMaskfspihy3xf">
                                  <rect
                                    id="SvgjsRect3733"
                                    width={287}
                                    height={257}
                                    x={-3}
                                    y={-4}
                                    rx={0}
                                    ry={0}
                                    opacity={1}
                                    strokeWidth={0}
                                    stroke="none"
                                    strokeDasharray={0}
                                    fill="#fff"
                                  />
                                </clipPath>
                                <clipPath id="forecastMaskfspihy3xf" />
                                <clipPath id="nonForecastMaskfspihy3xf" />
                                <clipPath id="gridRectMarkerMaskfspihy3xf">
                                  <rect
                                    id="SvgjsRect3734"
                                    width={286}
                                    height={253}
                                    x={-2}
                                    y={-2}
                                    rx={0}
                                    ry={0}
                                    opacity={1}
                                    strokeWidth={0}
                                    stroke="none"
                                    strokeDasharray={0}
                                    fill="#fff"
                                  />
                                </clipPath>
                              </defs>
                              <rect
                                id="SvgjsRect3732"
                                width="6.042857142857142"
                                height={249}
                                x={0}
                                y={0}
                                rx={0}
                                ry={0}
                                opacity={1}
                                strokeWidth={0}
                                strokeDasharray={3}
                                fill="url(#SvgjsLinearGradient3728)"
                                className="apexcharts-xcrosshairs"
                                y2={249}
                                filter="none"
                                fillOpacity="0.9"
                              />
                              <g id="SvgjsG3772" className="apexcharts-grid">
                                <g
                                  id="SvgjsG3773"
                                  className="apexcharts-gridlines-horizontal"
                                  style={{ display: "none" }}
                                >
                                  <line
                                    id="SvgjsLine3776"
                                    x1={0}
                                    y1={0}
                                    x2={282}
                                    y2={0}
                                    stroke="#f1f1f4"
                                    strokeDasharray={4}
                                    strokeLinecap="butt"
                                    className="apexcharts-gridline"
                                  />
                                  <line
                                    id="SvgjsLine3777"
                                    x1={0}
                                    y1="49.8"
                                    x2={282}
                                    y2="49.8"
                                    stroke="#f1f1f4"
                                    strokeDasharray={4}
                                    strokeLinecap="butt"
                                    className="apexcharts-gridline"
                                  />
                                  <line
                                    id="SvgjsLine3778"
                                    x1={0}
                                    y1="99.6"
                                    x2={282}
                                    y2="99.6"
                                    stroke="#f1f1f4"
                                    strokeDasharray={4}
                                    strokeLinecap="butt"
                                    className="apexcharts-gridline"
                                  />
                                  <line
                                    id="SvgjsLine3779"
                                    x1={0}
                                    y1="149.39999999999998"
                                    x2={282}
                                    y2="149.39999999999998"
                                    stroke="#f1f1f4"
                                    strokeDasharray={4}
                                    strokeLinecap="butt"
                                    className="apexcharts-gridline"
                                  />
                                  <line
                                    id="SvgjsLine3780"
                                    x1={0}
                                    y1="199.2"
                                    x2={282}
                                    y2="199.2"
                                    stroke="#f1f1f4"
                                    strokeDasharray={4}
                                    strokeLinecap="butt"
                                    className="apexcharts-gridline"
                                  />
                                  <line
                                    id="SvgjsLine3781"
                                    x1={0}
                                    y1={249}
                                    x2={282}
                                    y2={249}
                                    stroke="#f1f1f4"
                                    strokeDasharray={4}
                                    strokeLinecap="butt"
                                    className="apexcharts-gridline"
                                  />
                                </g>
                                <g
                                  id="SvgjsG3774"
                                  className="apexcharts-gridlines-vertical"
                                  style={{ display: "none" }}
                                />
                                <line
                                  id="SvgjsLine3783"
                                  x1={0}
                                  y1={249}
                                  x2={282}
                                  y2={249}
                                  stroke="transparent"
                                  strokeDasharray={0}
                                  strokeLinecap="butt"
                                />
                                <line
                                  id="SvgjsLine3782"
                                  x1={0}
                                  y1={1}
                                  x2={0}
                                  y2={249}
                                  stroke="transparent"
                                  strokeDasharray={0}
                                  strokeLinecap="butt"
                                />
                              </g>
                              <g
                                id="SvgjsG3775"
                                className="apexcharts-grid-borders"
                                style={{ display: "none" }}
                              />
                              <g
                                id="SvgjsG3735"
                                className="apexcharts-bar-series apexcharts-plot-series"
                              >
                                <g
                                  id="SvgjsG3736"
                                  className="apexcharts-series"
                                  rel={1}
                                  seriesname="NetxProfit"
                                >
                                  <path
                                    id="SvgjsPath3741"
                                    d="M 14.100000000000001 247.001 L 14.100000000000001 163.851 C 14.100000000000001 162.851 15.100000000000001 161.851 16.1 161.851 L 17.142857142857142 161.851 C 18.142857142857142 161.851 19.142857142857142 162.851 19.142857142857142 163.851 L 19.142857142857142 247.001 C 19.142857142857142 248.001 18.142857142857142 249.001 17.142857142857142 249.001 L 16.1 249.001 C 15.100000000000001 249.001 14.100000000000001 248.001 14.100000000000001 247.001 Z "
                                    fill="rgba(255,255,255,0.25)"
                                    fillOpacity={1}
                                    stroke="transparent"
                                    strokeOpacity={1}
                                    strokeLinecap="round"
                                    strokeWidth={1}
                                    strokeDasharray={0}
                                    className="apexcharts-bar-area"
                                    index={0}
                                    clipPath="url(#gridRectMaskfspihy3xf)"
                                    pathto="M 14.100000000000001 247.001 L 14.100000000000001 163.851 C 14.100000000000001 162.851 15.100000000000001 161.851 16.1 161.851 L 17.142857142857142 161.851 C 18.142857142857142 161.851 19.142857142857142 162.851 19.142857142857142 163.851 L 19.142857142857142 247.001 C 19.142857142857142 248.001 18.142857142857142 249.001 17.142857142857142 249.001 L 16.1 249.001 C 15.100000000000001 249.001 14.100000000000001 248.001 14.100000000000001 247.001 Z "
                                    pathfrom="M 14.100000000000001 249.001 L 14.100000000000001 249.001 L 19.142857142857142 249.001 L 19.142857142857142 249.001 L 19.142857142857142 249.001 L 19.142857142857142 249.001 L 19.142857142857142 249.001 L 14.100000000000001 249.001 Z"
                                    cy="161.85"
                                    cx="53.885714285714286"
                                    j={0}
                                    val={35}
                                    barheight="87.15"
                                    barwidth="6.042857142857142"
                                  />
                                  <path
                                    id="SvgjsPath3743"
                                    d="M 54.385714285714286 247.001 L 54.385714285714286 89.15100000000001 C 54.385714285714286 88.15100000000001 55.385714285714286 87.15100000000001 56.385714285714286 87.15100000000001 L 57.42857142857143 87.15100000000001 C 58.42857142857143 87.15100000000001 59.42857142857143 88.15100000000001 59.42857142857143 89.15100000000001 L 59.42857142857143 247.001 C 59.42857142857143 248.001 58.42857142857143 249.001 57.42857142857143 249.001 L 56.385714285714286 249.001 C 55.385714285714286 249.001 54.385714285714286 248.001 54.385714285714286 247.001 Z "
                                    fill="rgba(255,255,255,0.25)"
                                    fillOpacity={1}
                                    stroke="transparent"
                                    strokeOpacity={1}
                                    strokeLinecap="round"
                                    strokeWidth={1}
                                    strokeDasharray={0}
                                    className="apexcharts-bar-area"
                                    index={0}
                                    clipPath="url(#gridRectMaskfspihy3xf)"
                                    pathto="M 54.385714285714286 247.001 L 54.385714285714286 89.15100000000001 C 54.385714285714286 88.15100000000001 55.385714285714286 87.15100000000001 56.385714285714286 87.15100000000001 L 57.42857142857143 87.15100000000001 C 58.42857142857143 87.15100000000001 59.42857142857143 88.15100000000001 59.42857142857143 89.15100000000001 L 59.42857142857143 247.001 C 59.42857142857143 248.001 58.42857142857143 249.001 57.42857142857143 249.001 L 56.385714285714286 249.001 C 55.385714285714286 249.001 54.385714285714286 248.001 54.385714285714286 247.001 Z "
                                    pathfrom="M 54.385714285714286 249.001 L 54.385714285714286 249.001 L 59.42857142857143 249.001 L 59.42857142857143 249.001 L 59.42857142857143 249.001 L 59.42857142857143 249.001 L 59.42857142857143 249.001 L 54.385714285714286 249.001 Z"
                                    cy="87.15"
                                    cx="94.17142857142858"
                                    j={1}
                                    val={65}
                                    barheight="161.85"
                                    barwidth="6.042857142857142"
                                  />
                                  <path
                                    id="SvgjsPath3745"
                                    d="M 94.67142857142858 247.001 L 94.67142857142858 64.251 C 94.67142857142858 63.251000000000005 95.67142857142858 62.251 96.67142857142858 62.251 L 97.71428571428572 62.251 C 98.71428571428572 62.251 99.71428571428572 63.251000000000005 99.71428571428572 64.251 L 99.71428571428572 247.001 C 99.71428571428572 248.001 98.71428571428572 249.001 97.71428571428572 249.001 L 96.67142857142858 249.001 C 95.67142857142858 249.001 94.67142857142858 248.001 94.67142857142858 247.001 Z "
                                    fill="rgba(255,255,255,0.25)"
                                    fillOpacity={1}
                                    stroke="transparent"
                                    strokeOpacity={1}
                                    strokeLinecap="round"
                                    strokeWidth={1}
                                    strokeDasharray={0}
                                    className="apexcharts-bar-area"
                                    index={0}
                                    clipPath="url(#gridRectMaskfspihy3xf)"
                                    pathto="M 94.67142857142858 247.001 L 94.67142857142858 64.251 C 94.67142857142858 63.251000000000005 95.67142857142858 62.251 96.67142857142858 62.251 L 97.71428571428572 62.251 C 98.71428571428572 62.251 99.71428571428572 63.251000000000005 99.71428571428572 64.251 L 99.71428571428572 247.001 C 99.71428571428572 248.001 98.71428571428572 249.001 97.71428571428572 249.001 L 96.67142857142858 249.001 C 95.67142857142858 249.001 94.67142857142858 248.001 94.67142857142858 247.001 Z "
                                    pathfrom="M 94.67142857142858 249.001 L 94.67142857142858 249.001 L 99.71428571428572 249.001 L 99.71428571428572 249.001 L 99.71428571428572 249.001 L 99.71428571428572 249.001 L 99.71428571428572 249.001 L 94.67142857142858 249.001 Z"
                                    cy="62.25"
                                    cx="134.45714285714286"
                                    j={2}
                                    val={75}
                                    barheight="186.75"
                                    barwidth="6.042857142857142"
                                  />
                                  <path
                                    id="SvgjsPath3747"
                                    d="M 134.95714285714286 247.001 L 134.95714285714286 114.05100000000002 C 134.95714285714286 113.05100000000002 135.95714285714286 112.05100000000002 136.95714285714286 112.05100000000002 L 138 112.05100000000002 C 139 112.05100000000002 140 113.05100000000002 140 114.05100000000002 L 140 247.001 C 140 248.001 139 249.001 138 249.001 L 136.95714285714286 249.001 C 135.95714285714286 249.001 134.95714285714286 248.001 134.95714285714286 247.001 Z "
                                    fill="rgba(255,255,255,0.25)"
                                    fillOpacity={1}
                                    stroke="transparent"
                                    strokeOpacity={1}
                                    strokeLinecap="round"
                                    strokeWidth={1}
                                    strokeDasharray={0}
                                    className="apexcharts-bar-area"
                                    index={0}
                                    clipPath="url(#gridRectMaskfspihy3xf)"
                                    pathto="M 134.95714285714286 247.001 L 134.95714285714286 114.05100000000002 C 134.95714285714286 113.05100000000002 135.95714285714286 112.05100000000002 136.95714285714286 112.05100000000002 L 138 112.05100000000002 C 139 112.05100000000002 140 113.05100000000002 140 114.05100000000002 L 140 247.001 C 140 248.001 139 249.001 138 249.001 L 136.95714285714286 249.001 C 135.95714285714286 249.001 134.95714285714286 248.001 134.95714285714286 247.001 Z "
                                    pathfrom="M 134.95714285714286 249.001 L 134.95714285714286 249.001 L 140 249.001 L 140 249.001 L 140 249.001 L 140 249.001 L 140 249.001 L 134.95714285714286 249.001 Z"
                                    cy="112.05000000000001"
                                    cx="174.74285714285713"
                                    j={3}
                                    val={55}
                                    barheight="136.95"
                                    barwidth="6.042857142857142"
                                  />
                                  <path
                                    id="SvgjsPath3749"
                                    d="M 175.24285714285713 247.001 L 175.24285714285713 138.951 C 175.24285714285713 137.951 176.24285714285713 136.951 177.24285714285713 136.951 L 178.28571428571428 136.951 C 179.28571428571428 136.951 180.28571428571428 137.951 180.28571428571428 138.951 L 180.28571428571428 247.001 C 180.28571428571428 248.001 179.28571428571428 249.001 178.28571428571428 249.001 L 177.24285714285713 249.001 C 176.24285714285713 249.001 175.24285714285713 248.001 175.24285714285713 247.001 Z "
                                    fill="rgba(255,255,255,0.25)"
                                    fillOpacity={1}
                                    stroke="transparent"
                                    strokeOpacity={1}
                                    strokeLinecap="round"
                                    strokeWidth={1}
                                    strokeDasharray={0}
                                    className="apexcharts-bar-area"
                                    index={0}
                                    clipPath="url(#gridRectMaskfspihy3xf)"
                                    pathto="M 175.24285714285713 247.001 L 175.24285714285713 138.951 C 175.24285714285713 137.951 176.24285714285713 136.951 177.24285714285713 136.951 L 178.28571428571428 136.951 C 179.28571428571428 136.951 180.28571428571428 137.951 180.28571428571428 138.951 L 180.28571428571428 247.001 C 180.28571428571428 248.001 179.28571428571428 249.001 178.28571428571428 249.001 L 177.24285714285713 249.001 C 176.24285714285713 249.001 175.24285714285713 248.001 175.24285714285713 247.001 Z "
                                    pathfrom="M 175.24285714285713 249.001 L 175.24285714285713 249.001 L 180.28571428571428 249.001 L 180.28571428571428 249.001 L 180.28571428571428 249.001 L 180.28571428571428 249.001 L 180.28571428571428 249.001 L 175.24285714285713 249.001 Z"
                                    cy="136.95"
                                    cx="215.0285714285714"
                                    j={4}
                                    val={45}
                                    barheight="112.05"
                                    barwidth="6.042857142857142"
                                  />
                                  <path
                                    id="SvgjsPath3751"
                                    d="M 215.5285714285714 247.001 L 215.5285714285714 101.601 C 215.5285714285714 100.601 216.5285714285714 99.601 217.5285714285714 99.601 L 218.57142857142856 99.601 C 219.57142857142856 99.601 220.57142857142856 100.601 220.57142857142856 101.601 L 220.57142857142856 247.001 C 220.57142857142856 248.001 219.57142857142856 249.001 218.57142857142856 249.001 L 217.5285714285714 249.001 C 216.5285714285714 249.001 215.5285714285714 248.001 215.5285714285714 247.001 Z "
                                    fill="rgba(255,255,255,0.25)"
                                    fillOpacity={1}
                                    stroke="transparent"
                                    strokeOpacity={1}
                                    strokeLinecap="round"
                                    strokeWidth={1}
                                    strokeDasharray={0}
                                    className="apexcharts-bar-area"
                                    index={0}
                                    clipPath="url(#gridRectMaskfspihy3xf)"
                                    pathto="M 215.5285714285714 247.001 L 215.5285714285714 101.601 C 215.5285714285714 100.601 216.5285714285714 99.601 217.5285714285714 99.601 L 218.57142857142856 99.601 C 219.57142857142856 99.601 220.57142857142856 100.601 220.57142857142856 101.601 L 220.57142857142856 247.001 C 220.57142857142856 248.001 219.57142857142856 249.001 218.57142857142856 249.001 L 217.5285714285714 249.001 C 216.5285714285714 249.001 215.5285714285714 248.001 215.5285714285714 247.001 Z "
                                    pathfrom="M 215.5285714285714 249.001 L 215.5285714285714 249.001 L 220.57142857142856 249.001 L 220.57142857142856 249.001 L 220.57142857142856 249.001 L 220.57142857142856 249.001 L 220.57142857142856 249.001 L 215.5285714285714 249.001 Z"
                                    cy="99.6"
                                    cx="255.3142857142857"
                                    j={5}
                                    val={60}
                                    barheight="149.4"
                                    barwidth="6.042857142857142"
                                  />
                                  <path
                                    id="SvgjsPath3753"
                                    d="M 255.8142857142857 247.001 L 255.8142857142857 114.05100000000002 C 255.8142857142857 113.05100000000002 256.8142857142857 112.05100000000002 257.8142857142857 112.05100000000002 L 258.85714285714283 112.05100000000002 C 259.85714285714283 112.05100000000002 260.85714285714283 113.05100000000002 260.85714285714283 114.05100000000002 L 260.85714285714283 247.001 C 260.85714285714283 248.001 259.85714285714283 249.001 258.85714285714283 249.001 L 257.8142857142857 249.001 C 256.8142857142857 249.001 255.8142857142857 248.001 255.8142857142857 247.001 Z "
                                    fill="rgba(255,255,255,0.25)"
                                    fillOpacity={1}
                                    stroke="transparent"
                                    strokeOpacity={1}
                                    strokeLinecap="round"
                                    strokeWidth={1}
                                    strokeDasharray={0}
                                    className="apexcharts-bar-area"
                                    index={0}
                                    clipPath="url(#gridRectMaskfspihy3xf)"
                                    pathto="M 255.8142857142857 247.001 L 255.8142857142857 114.05100000000002 C 255.8142857142857 113.05100000000002 256.8142857142857 112.05100000000002 257.8142857142857 112.05100000000002 L 258.85714285714283 112.05100000000002 C 259.85714285714283 112.05100000000002 260.85714285714283 113.05100000000002 260.85714285714283 114.05100000000002 L 260.85714285714283 247.001 C 260.85714285714283 248.001 259.85714285714283 249.001 258.85714285714283 249.001 L 257.8142857142857 249.001 C 256.8142857142857 249.001 255.8142857142857 248.001 255.8142857142857 247.001 Z "
                                    pathfrom="M 255.8142857142857 249.001 L 255.8142857142857 249.001 L 260.85714285714283 249.001 L 260.85714285714283 249.001 L 260.85714285714283 249.001 L 260.85714285714283 249.001 L 260.85714285714283 249.001 L 255.8142857142857 249.001 Z"
                                    cy="112.05000000000001"
                                    cx="295.59999999999997"
                                    j={6}
                                    val={55}
                                    barheight="136.95"
                                    barwidth="6.042857142857142"
                                  />
                                  <g
                                    id="SvgjsG3738"
                                    className="apexcharts-bar-goals-markers"
                                  >
                                    <g
                                      id="SvgjsG3740"
                                      className="apexcharts-bar-goals-groups apexcharts-hidden-element-shown"
                                      clipPath="url(#gridRectMarkerMaskfspihy3xf)"
                                    />
                                    <g
                                      id="SvgjsG3742"
                                      className="apexcharts-bar-goals-groups apexcharts-hidden-element-shown"
                                      clipPath="url(#gridRectMarkerMaskfspihy3xf)"
                                    />
                                    <g
                                      id="SvgjsG3744"
                                      className="apexcharts-bar-goals-groups apexcharts-hidden-element-shown"
                                      clipPath="url(#gridRectMarkerMaskfspihy3xf)"
                                    />
                                    <g
                                      id="SvgjsG3746"
                                      className="apexcharts-bar-goals-groups apexcharts-hidden-element-shown"
                                      clipPath="url(#gridRectMarkerMaskfspihy3xf)"
                                    />
                                    <g
                                      id="SvgjsG3748"
                                      className="apexcharts-bar-goals-groups apexcharts-hidden-element-shown"
                                      clipPath="url(#gridRectMarkerMaskfspihy3xf)"
                                    />
                                    <g
                                      id="SvgjsG3750"
                                      className="apexcharts-bar-goals-groups apexcharts-hidden-element-shown"
                                      clipPath="url(#gridRectMarkerMaskfspihy3xf)"
                                    />
                                    <g
                                      id="SvgjsG3752"
                                      className="apexcharts-bar-goals-groups apexcharts-hidden-element-shown"
                                      clipPath="url(#gridRectMarkerMaskfspihy3xf)"
                                    />
                                  </g>
                                  <g
                                    id="SvgjsG3739"
                                    className="apexcharts-bar-shadows apexcharts-hidden-element-shown"
                                  />
                                </g>
                                <g
                                  id="SvgjsG3754"
                                  className="apexcharts-series"
                                  rel={2}
                                  seriesname="Revenue"
                                >
                                  <path
                                    id="SvgjsPath3759"
                                    d="M 20.142857142857142 247.001 L 20.142857142857142 151.401 C 20.142857142857142 150.401 21.142857142857142 149.401 22.142857142857142 149.401 L 23.185714285714283 149.401 C 24.185714285714283 149.401 25.185714285714283 150.401 25.185714285714283 151.401 L 25.185714285714283 247.001 C 25.185714285714283 248.001 24.185714285714283 249.001 23.185714285714283 249.001 L 22.142857142857142 249.001 C 21.142857142857142 249.001 20.142857142857142 248.001 20.142857142857142 247.001 Z "
                                    fill="rgba(255,255,255,1)"
                                    fillOpacity={1}
                                    stroke="transparent"
                                    strokeOpacity={1}
                                    strokeLinecap="round"
                                    strokeWidth={1}
                                    strokeDasharray={0}
                                    className="apexcharts-bar-area"
                                    index={1}
                                    clipPath="url(#gridRectMaskfspihy3xf)"
                                    pathto="M 20.142857142857142 247.001 L 20.142857142857142 151.401 C 20.142857142857142 150.401 21.142857142857142 149.401 22.142857142857142 149.401 L 23.185714285714283 149.401 C 24.185714285714283 149.401 25.185714285714283 150.401 25.185714285714283 151.401 L 25.185714285714283 247.001 C 25.185714285714283 248.001 24.185714285714283 249.001 23.185714285714283 249.001 L 22.142857142857142 249.001 C 21.142857142857142 249.001 20.142857142857142 248.001 20.142857142857142 247.001 Z "
                                    pathfrom="M 20.142857142857142 249.001 L 20.142857142857142 249.001 L 25.185714285714283 249.001 L 25.185714285714283 249.001 L 25.185714285714283 249.001 L 25.185714285714283 249.001 L 25.185714285714283 249.001 L 20.142857142857142 249.001 Z"
                                    cy="149.4"
                                    cx="59.92857142857143"
                                    j={0}
                                    val={40}
                                    barheight="99.6"
                                    barwidth="6.042857142857142"
                                  />
                                  <path
                                    id="SvgjsPath3761"
                                    d="M 60.42857142857143 247.001 L 60.42857142857143 76.701 C 60.42857142857143 75.701 61.42857142857143 74.701 62.42857142857143 74.701 L 63.471428571428575 74.701 C 64.47142857142858 74.701 65.47142857142858 75.701 65.47142857142858 76.701 L 65.47142857142858 247.001 C 65.47142857142858 248.001 64.47142857142858 249.001 63.471428571428575 249.001 L 62.42857142857143 249.001 C 61.42857142857143 249.001 60.42857142857143 248.001 60.42857142857143 247.001 Z "
                                    fill="rgba(255,255,255,1)"
                                    fillOpacity={1}
                                    stroke="transparent"
                                    strokeOpacity={1}
                                    strokeLinecap="round"
                                    strokeWidth={1}
                                    strokeDasharray={0}
                                    className="apexcharts-bar-area"
                                    index={1}
                                    clipPath="url(#gridRectMaskfspihy3xf)"
                                    pathto="M 60.42857142857143 247.001 L 60.42857142857143 76.701 C 60.42857142857143 75.701 61.42857142857143 74.701 62.42857142857143 74.701 L 63.471428571428575 74.701 C 64.47142857142858 74.701 65.47142857142858 75.701 65.47142857142858 76.701 L 65.47142857142858 247.001 C 65.47142857142858 248.001 64.47142857142858 249.001 63.471428571428575 249.001 L 62.42857142857143 249.001 C 61.42857142857143 249.001 60.42857142857143 248.001 60.42857142857143 247.001 Z "
                                    pathfrom="M 60.42857142857143 249.001 L 60.42857142857143 249.001 L 65.47142857142858 249.001 L 65.47142857142858 249.001 L 65.47142857142858 249.001 L 65.47142857142858 249.001 L 65.47142857142858 249.001 L 60.42857142857143 249.001 Z"
                                    cy="74.69999999999999"
                                    cx="100.21428571428572"
                                    j={1}
                                    val={70}
                                    barheight="174.3"
                                    barwidth="6.042857142857142"
                                  />
                                  <path
                                    id="SvgjsPath3763"
                                    d="M 100.71428571428572 247.001 L 100.71428571428572 51.80100000000001 C 100.71428571428572 50.80100000000001 101.71428571428572 49.80100000000001 102.71428571428572 49.80100000000001 L 103.75714285714287 49.80100000000001 C 104.75714285714287 49.80100000000001 105.75714285714287 50.80100000000001 105.75714285714287 51.80100000000001 L 105.75714285714287 247.001 C 105.75714285714287 248.001 104.75714285714287 249.001 103.75714285714287 249.001 L 102.71428571428572 249.001 C 101.71428571428572 249.001 100.71428571428572 248.001 100.71428571428572 247.001 Z "
                                    fill="rgba(255,255,255,1)"
                                    fillOpacity={1}
                                    stroke="transparent"
                                    strokeOpacity={1}
                                    strokeLinecap="round"
                                    strokeWidth={1}
                                    strokeDasharray={0}
                                    className="apexcharts-bar-area"
                                    index={1}
                                    clipPath="url(#gridRectMaskfspihy3xf)"
                                    pathto="M 100.71428571428572 247.001 L 100.71428571428572 51.80100000000001 C 100.71428571428572 50.80100000000001 101.71428571428572 49.80100000000001 102.71428571428572 49.80100000000001 L 103.75714285714287 49.80100000000001 C 104.75714285714287 49.80100000000001 105.75714285714287 50.80100000000001 105.75714285714287 51.80100000000001 L 105.75714285714287 247.001 C 105.75714285714287 248.001 104.75714285714287 249.001 103.75714285714287 249.001 L 102.71428571428572 249.001 C 101.71428571428572 249.001 100.71428571428572 248.001 100.71428571428572 247.001 Z "
                                    pathfrom="M 100.71428571428572 249.001 L 100.71428571428572 249.001 L 105.75714285714287 249.001 L 105.75714285714287 249.001 L 105.75714285714287 249.001 L 105.75714285714287 249.001 L 105.75714285714287 249.001 L 100.71428571428572 249.001 Z"
                                    cy="49.80000000000001"
                                    cx="140.5"
                                    j={2}
                                    val={80}
                                    barheight="199.2"
                                    barwidth="6.042857142857142"
                                  />
                                  <path
                                    id="SvgjsPath3765"
                                    d="M 141 247.001 L 141 101.601 C 141 100.601 142 99.601 143 99.601 L 144.04285714285714 99.601 C 145.04285714285714 99.601 146.04285714285714 100.601 146.04285714285714 101.601 L 146.04285714285714 247.001 C 146.04285714285714 248.001 145.04285714285714 249.001 144.04285714285714 249.001 L 143 249.001 C 142 249.001 141 248.001 141 247.001 Z "
                                    fill="rgba(255,255,255,1)"
                                    fillOpacity={1}
                                    stroke="transparent"
                                    strokeOpacity={1}
                                    strokeLinecap="round"
                                    strokeWidth={1}
                                    strokeDasharray={0}
                                    className="apexcharts-bar-area"
                                    index={1}
                                    clipPath="url(#gridRectMaskfspihy3xf)"
                                    pathto="M 141 247.001 L 141 101.601 C 141 100.601 142 99.601 143 99.601 L 144.04285714285714 99.601 C 145.04285714285714 99.601 146.04285714285714 100.601 146.04285714285714 101.601 L 146.04285714285714 247.001 C 146.04285714285714 248.001 145.04285714285714 249.001 144.04285714285714 249.001 L 143 249.001 C 142 249.001 141 248.001 141 247.001 Z "
                                    pathfrom="M 141 249.001 L 141 249.001 L 146.04285714285714 249.001 L 146.04285714285714 249.001 L 146.04285714285714 249.001 L 146.04285714285714 249.001 L 146.04285714285714 249.001 L 141 249.001 Z"
                                    cy="99.6"
                                    cx="180.78571428571428"
                                    j={3}
                                    val={60}
                                    barheight="149.4"
                                    barwidth="6.042857142857142"
                                  />
                                  <path
                                    id="SvgjsPath3767"
                                    d="M 181.28571428571428 247.001 L 181.28571428571428 126.501 C 181.28571428571428 125.501 182.28571428571428 124.501 183.28571428571428 124.501 L 184.32857142857142 124.501 C 185.32857142857142 124.501 186.32857142857142 125.501 186.32857142857142 126.501 L 186.32857142857142 247.001 C 186.32857142857142 248.001 185.32857142857142 249.001 184.32857142857142 249.001 L 183.28571428571428 249.001 C 182.28571428571428 249.001 181.28571428571428 248.001 181.28571428571428 247.001 Z "
                                    fill="rgba(255,255,255,1)"
                                    fillOpacity={1}
                                    stroke="transparent"
                                    strokeOpacity={1}
                                    strokeLinecap="round"
                                    strokeWidth={1}
                                    strokeDasharray={0}
                                    className="apexcharts-bar-area"
                                    index={1}
                                    clipPath="url(#gridRectMaskfspihy3xf)"
                                    pathto="M 181.28571428571428 247.001 L 181.28571428571428 126.501 C 181.28571428571428 125.501 182.28571428571428 124.501 183.28571428571428 124.501 L 184.32857142857142 124.501 C 185.32857142857142 124.501 186.32857142857142 125.501 186.32857142857142 126.501 L 186.32857142857142 247.001 C 186.32857142857142 248.001 185.32857142857142 249.001 184.32857142857142 249.001 L 183.28571428571428 249.001 C 182.28571428571428 249.001 181.28571428571428 248.001 181.28571428571428 247.001 Z "
                                    pathfrom="M 181.28571428571428 249.001 L 181.28571428571428 249.001 L 186.32857142857142 249.001 L 186.32857142857142 249.001 L 186.32857142857142 249.001 L 186.32857142857142 249.001 L 186.32857142857142 249.001 L 181.28571428571428 249.001 Z"
                                    cy="124.5"
                                    cx="221.07142857142856"
                                    j={4}
                                    val={50}
                                    barheight="124.5"
                                    barwidth="6.042857142857142"
                                  />
                                  <path
                                    id="SvgjsPath3769"
                                    d="M 221.57142857142856 247.001 L 221.57142857142856 89.15100000000001 C 221.57142857142856 88.15100000000001 222.57142857142856 87.15100000000001 223.57142857142856 87.15100000000001 L 224.6142857142857 87.15100000000001 C 225.6142857142857 87.15100000000001 226.6142857142857 88.15100000000001 226.6142857142857 89.15100000000001 L 226.6142857142857 247.001 C 226.6142857142857 248.001 225.6142857142857 249.001 224.6142857142857 249.001 L 223.57142857142856 249.001 C 222.57142857142856 249.001 221.57142857142856 248.001 221.57142857142856 247.001 Z "
                                    fill="rgba(255,255,255,1)"
                                    fillOpacity={1}
                                    stroke="transparent"
                                    strokeOpacity={1}
                                    strokeLinecap="round"
                                    strokeWidth={1}
                                    strokeDasharray={0}
                                    className="apexcharts-bar-area"
                                    index={1}
                                    clipPath="url(#gridRectMaskfspihy3xf)"
                                    pathto="M 221.57142857142856 247.001 L 221.57142857142856 89.15100000000001 C 221.57142857142856 88.15100000000001 222.57142857142856 87.15100000000001 223.57142857142856 87.15100000000001 L 224.6142857142857 87.15100000000001 C 225.6142857142857 87.15100000000001 226.6142857142857 88.15100000000001 226.6142857142857 89.15100000000001 L 226.6142857142857 247.001 C 226.6142857142857 248.001 225.6142857142857 249.001 224.6142857142857 249.001 L 223.57142857142856 249.001 C 222.57142857142856 249.001 221.57142857142856 248.001 221.57142857142856 247.001 Z "
                                    pathfrom="M 221.57142857142856 249.001 L 221.57142857142856 249.001 L 226.6142857142857 249.001 L 226.6142857142857 249.001 L 226.6142857142857 249.001 L 226.6142857142857 249.001 L 226.6142857142857 249.001 L 221.57142857142856 249.001 Z"
                                    cy="87.15"
                                    cx="261.35714285714283"
                                    j={5}
                                    val={65}
                                    barheight="161.85"
                                    barwidth="6.042857142857142"
                                  />
                                  <path
                                    id="SvgjsPath3771"
                                    d="M 261.85714285714283 247.001 L 261.85714285714283 101.601 C 261.85714285714283 100.601 262.85714285714283 99.601 263.85714285714283 99.601 L 264.9 99.601 C 265.9 99.601 266.9 100.601 266.9 101.601 L 266.9 247.001 C 266.9 248.001 265.9 249.001 264.9 249.001 L 263.85714285714283 249.001 C 262.85714285714283 249.001 261.85714285714283 248.001 261.85714285714283 247.001 Z "
                                    fill="rgba(255,255,255,1)"
                                    fillOpacity={1}
                                    stroke="transparent"
                                    strokeOpacity={1}
                                    strokeLinecap="round"
                                    strokeWidth={1}
                                    strokeDasharray={0}
                                    className="apexcharts-bar-area"
                                    index={1}
                                    clipPath="url(#gridRectMaskfspihy3xf)"
                                    pathto="M 261.85714285714283 247.001 L 261.85714285714283 101.601 C 261.85714285714283 100.601 262.85714285714283 99.601 263.85714285714283 99.601 L 264.9 99.601 C 265.9 99.601 266.9 100.601 266.9 101.601 L 266.9 247.001 C 266.9 248.001 265.9 249.001 264.9 249.001 L 263.85714285714283 249.001 C 262.85714285714283 249.001 261.85714285714283 248.001 261.85714285714283 247.001 Z "
                                    pathfrom="M 261.85714285714283 249.001 L 261.85714285714283 249.001 L 266.9 249.001 L 266.9 249.001 L 266.9 249.001 L 266.9 249.001 L 266.9 249.001 L 261.85714285714283 249.001 Z"
                                    cy="99.6"
                                    cx="301.6428571428571"
                                    j={6}
                                    val={60}
                                    barheight="149.4"
                                    barwidth="6.042857142857142"
                                  />
                                  <g
                                    id="SvgjsG3756"
                                    className="apexcharts-bar-goals-markers"
                                  >
                                    <g
                                      id="SvgjsG3758"
                                      className="apexcharts-bar-goals-groups apexcharts-hidden-element-shown"
                                      clipPath="url(#gridRectMarkerMaskfspihy3xf)"
                                    />
                                    <g
                                      id="SvgjsG3760"
                                      className="apexcharts-bar-goals-groups apexcharts-hidden-element-shown"
                                      clipPath="url(#gridRectMarkerMaskfspihy3xf)"
                                    />
                                    <g
                                      id="SvgjsG3762"
                                      className="apexcharts-bar-goals-groups apexcharts-hidden-element-shown"
                                      clipPath="url(#gridRectMarkerMaskfspihy3xf)"
                                    />
                                    <g
                                      id="SvgjsG3764"
                                      className="apexcharts-bar-goals-groups apexcharts-hidden-element-shown"
                                      clipPath="url(#gridRectMarkerMaskfspihy3xf)"
                                    />
                                    <g
                                      id="SvgjsG3766"
                                      className="apexcharts-bar-goals-groups apexcharts-hidden-element-shown"
                                      clipPath="url(#gridRectMarkerMaskfspihy3xf)"
                                    />
                                    <g
                                      id="SvgjsG3768"
                                      className="apexcharts-bar-goals-groups apexcharts-hidden-element-shown"
                                      clipPath="url(#gridRectMarkerMaskfspihy3xf)"
                                    />
                                    <g
                                      id="SvgjsG3770"
                                      className="apexcharts-bar-goals-groups apexcharts-hidden-element-shown"
                                      clipPath="url(#gridRectMarkerMaskfspihy3xf)"
                                    />
                                  </g>
                                  <g
                                    id="SvgjsG3757"
                                    className="apexcharts-bar-shadows apexcharts-hidden-element-shown"
                                  />
                                </g>
                                <g
                                  id="SvgjsG3737"
                                  className="apexcharts-datalabels apexcharts-hidden-element-shown"
                                />
                                <g
                                  id="SvgjsG3755"
                                  className="apexcharts-datalabels apexcharts-hidden-element-shown"
                                />
                              </g>
                              <line
                                id="SvgjsLine3784"
                                x1={0}
                                y1={0}
                                x2={282}
                                y2={0}
                                stroke="#b6b6b6"
                                strokeDasharray={0}
                                strokeWidth={1}
                                strokeLinecap="butt"
                                className="apexcharts-ycrosshairs"
                              />
                              <line
                                id="SvgjsLine3785"
                                x1={0}
                                y1={0}
                                x2={282}
                                y2={0}
                                strokeDasharray={0}
                                strokeWidth={0}
                                strokeLinecap="butt"
                                className="apexcharts-ycrosshairs-hidden"
                              />
                              <g
                                id="SvgjsG3786"
                                className="apexcharts-xaxis"
                                transform="translate(0, 0)"
                              >
                                <g
                                  id="SvgjsG3787"
                                  className="apexcharts-xaxis-texts-g"
                                  transform="translate(0, -4)"
                                />
                              </g>
                              <g
                                id="SvgjsG3795"
                                className="apexcharts-yaxis-annotations"
                              />
                              <g
                                id="SvgjsG3796"
                                className="apexcharts-xaxis-annotations"
                              />
                              <g
                                id="SvgjsG3797"
                                className="apexcharts-point-annotations"
                              />
                            </g>
                          </svg>
                          <div className="apexcharts-tooltip apexcharts-theme-light">
                            <div
                              className="apexcharts-tooltip-title"
                              style={{ fontFamily: "inherit", fontSize: 12 }}
                            />
                            <div
                              className="apexcharts-tooltip-series-group"
                              style={{ order: 1 }}
                            >
                              <span
                                className="apexcharts-tooltip-marker"
                                style={{
                                  backgroundColor: "rgb(255, 255, 255)",
                                }}
                              />
                              <div
                                className="apexcharts-tooltip-text"
                                style={{ fontFamily: "inherit", fontSize: 12 }}
                              >
                                <div className="apexcharts-tooltip-y-group">
                                  <span className="apexcharts-tooltip-text-y-label" />
                                  <span className="apexcharts-tooltip-text-y-value" />
                                </div>
                                <div className="apexcharts-tooltip-goals-group">
                                  <span className="apexcharts-tooltip-text-goals-label" />
                                  <span className="apexcharts-tooltip-text-goals-value" />
                                </div>
                                <div className="apexcharts-tooltip-z-group">
                                  <span className="apexcharts-tooltip-text-z-label" />
                                  <span className="apexcharts-tooltip-text-z-value" />
                                </div>
                              </div>
                            </div>
                            <div
                              className="apexcharts-tooltip-series-group"
                              style={{ order: 2 }}
                            >
                              <span
                                className="apexcharts-tooltip-marker"
                                style={{
                                  backgroundColor: "rgb(255, 255, 255)",
                                }}
                              />
                              <div
                                className="apexcharts-tooltip-text"
                                style={{ fontFamily: "inherit", fontSize: 12 }}
                              >
                                <div className="apexcharts-tooltip-y-group">
                                  <span className="apexcharts-tooltip-text-y-label" />
                                  <span className="apexcharts-tooltip-text-y-value" />
                                </div>
                                <div className="apexcharts-tooltip-goals-group">
                                  <span className="apexcharts-tooltip-text-goals-label" />
                                  <span className="apexcharts-tooltip-text-goals-value" />
                                </div>
                                <div className="apexcharts-tooltip-z-group">
                                  <span className="apexcharts-tooltip-text-z-label" />
                                  <span className="apexcharts-tooltip-text-z-value" />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="apexcharts-yaxistooltip apexcharts-yaxistooltip-0 apexcharts-yaxistooltip-left apexcharts-theme-light">
                            <div className="apexcharts-yaxistooltip-text" />
                          </div>
                        </div>
                      </div>
                      {/*end::Chart*/}
                      {/*begin::Stats*/}
                      <div className="card-rounded bg-body mt-n10 position-relative card-px py-10">
                        {/*begin::Row*/}
                        <div className="row g-0 mb-7">
                          {/*begin::Col*/}
                          <div className="col mx-5">
                            <div className="fs-6 text-gray-500">
                              Total Revenue
                            </div>
                            <div className="fs-2 fw-bold text-gray-800">
                              $650
                            </div>
                          </div>
                          {/*end::Col*/}
                          {/*begin::Col*/}
                          <div className="col mx-5">
                            <div className="fs-6 text-gray-500">Comissions</div>
                            <div className="fs-2 fw-bold text-gray-800">
                              $29,500
                            </div>
                          </div>
                          {/*end::Col*/}
                        </div>
                        {/*end::Row*/}
                        {/*begin::Row*/}
                        <div className="row g-0">
                          {/*begin::Col*/}
                          <div className="col mx-5">
                            <div className="fs-6 text-gray-500">
                              Revenue FY-2023
                            </div>
                            <div className="fs-2 fw-bold text-gray-800">
                              $55,000
                            </div>
                          </div>
                          {/*end::Col*/}
                          {/*begin::Col*/}
                          <div className="col mx-5">
                            <div className="fs-6 text-gray-500">
                              Annual Tax - 2024
                            </div>
                            <div className="fs-2 fw-bold text-gray-800">
                              $1,130,600
                            </div>
                          </div>
                          {/*end::Col*/}
                        </div>
                        {/*end::Row*/}
                      </div>
                      {/*end::Stats*/}
                    </div>
                    {/*end::Body*/}
                  </div>
                </div>

                <div className="col-md-7">
                  <div className="card card-flush">
                    <div className="card-header pt-7">
                      <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bold text-gray-800">
                          Popular Services
                        </span>
                        <span className="text-gray-500 mt-1 fw-semibold fs-6">
                          Based on Revenue Generated
                        </span>
                      </h3>
                      <div className="card-toolbar">
                        <div
                          className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px"
                          data-kt-menu="true"
                        >
                          <div className="menu-item px-3">
                            <div className="menu-content fs-6 text-gray-900 fw-bold px-3 py-4">
                              Quick Actions
                            </div>
                          </div>
                          <div className="separator mb-3 opacity-75" />
                          <div className="menu-item px-3">
                            <a href="#" className="menu-link px-3">
                              New Ticket
                            </a>
                          </div>
                          <div className="menu-item px-3">
                            <a href="#" className="menu-link px-3">
                              New Customer
                            </a>
                          </div>
                          <div
                            className="menu-item px-3"
                            data-kt-menu-trigger="hover"
                            data-kt-menu-placement="right-start"
                          >
                            <a href="#" className="menu-link px-3">
                              <span className="menu-title">New Group</span>
                              <span className="menu-arrow" />
                            </a>
                            <div className="menu-sub menu-sub-dropdown w-175px py-4">
                              <div className="menu-item px-3">
                                <a href="#" className="menu-link px-3">
                                  Admin Group
                                </a>
                              </div>
                              <div className="menu-item px-3">
                                <a href="#" className="menu-link px-3">
                                  Staff Group
                                </a>
                              </div>
                              <div className="menu-item px-3">
                                <a href="#" className="menu-link px-3">
                                  Member Group
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="menu-item px-3">
                            <a href="#" className="menu-link px-3">
                              New Contact
                            </a>
                          </div>
                          <div className="separator mt-3 opacity-75" />
                          <div className="menu-item px-3">
                            <div className="menu-content px-3 py-3">
                              <a
                                className="btn btn-primary btn-sm px-4"
                                href="#"
                              >
                                Generate Reports
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="w-100">
                        <div className="d-flex align-items-center">
                          <div className="symbol symbol-30px me-5">
                            <span className="symbol-label">
                              <i className="ki-outline ki-rocket fs-3 text-gray-600" />
                            </span>
                          </div>
                          <div className="d-flex align-items-center flex-stack flex-wrap d-grid gap-1 flex-row-fluid">
                            <div className="me-5">
                              <a
                                href="#"
                                className="text-gray-800 fw-bold text-hover-primary fs-6"
                              >
                                Direct Source
                              </a>
                              <span className="text-gray-500 fw-semibold fs-7 d-block text-start ps-0">
                                Direct link clicks
                              </span>
                            </div>
                            <div className="d-flex align-items-center">
                              <span className="text-gray-800 fw-bold fs-4 me-3">
                                1,067
                              </span>
                              <span className="badge badge-light-success fs-base">
                                <i className="ki-outline ki-arrow-up fs-5 text-success ms-n1" />
                                2.6%
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="separator separator-dashed my-3" />
                        <div className="d-flex align-items-center">
                          <div className="symbol symbol-30px me-5">
                            <span className="symbol-label">
                              <i className="ki-outline ki-tiktok fs-3 text-gray-600" />
                            </span>
                          </div>
                          <div className="d-flex align-items-center flex-stack flex-wrap d-grid gap-1 flex-row-fluid">
                            <div className="me-5">
                              <a
                                href="#"
                                className="text-gray-800 fw-bold text-hover-primary fs-6"
                              >
                                Social Networks
                              </a>
                              <span className="text-gray-500 fw-semibold fs-7 d-block text-start ps-0">
                                All Social Channels
                              </span>
                            </div>
                            <div className="d-flex align-items-center">
                              <span className="text-gray-800 fw-bold fs-4 me-3">
                                24,588
                              </span>
                              <span className="badge badge-light-success fs-base">
                                <i className="ki-outline ki-arrow-up fs-5 text-success ms-n1" />
                                4.1%
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="separator separator-dashed my-3" />
                        <div className="d-flex align-items-center">
                          <div className="symbol symbol-30px me-5">
                            <span className="symbol-label">
                              <i className="ki-outline ki-sms fs-3 text-gray-600" />
                            </span>
                          </div>
                          <div className="d-flex align-items-center flex-stack flex-wrap d-grid gap-1 flex-row-fluid">
                            <div className="me-5">
                              <a
                                href="#"
                                className="text-gray-800 fw-bold text-hover-primary fs-6"
                              >
                                Email Newsletter
                              </a>
                              <span className="text-gray-500 fw-semibold fs-7 d-block text-start ps-0">
                                Mailchimp Campaigns
                              </span>
                            </div>
                            <div className="d-flex align-items-center">
                              <span className="text-gray-800 fw-bold fs-4 me-3">
                                794
                              </span>
                              <span className="badge badge-light-success fs-base">
                                <i className="ki-outline ki-arrow-up fs-5 text-success ms-n1" />
                                0.2%
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="separator separator-dashed my-3" />
                        <div className="d-flex align-items-center">
                          <div className="symbol symbol-30px me-5">
                            <span className="symbol-label">
                              <i className="ki-outline ki-icon fs-3 text-gray-600" />
                            </span>
                          </div>
                          <div className="d-flex align-items-center flex-stack flex-wrap d-grid gap-1 flex-row-fluid">
                            <div className="me-5">
                              <a
                                href="#"
                                className="text-gray-800 fw-bold text-hover-primary fs-6"
                              >
                                Referrals
                              </a>
                              <span className="text-gray-500 fw-semibold fs-7 d-block text-start ps-0">
                                Impact Radius visits
                              </span>
                            </div>
                            <div className="d-flex align-items-center">
                              <span className="text-gray-800 fw-bold fs-4 me-3">
                                6,578
                              </span>
                              <span className="badge badge-light-danger fs-base">
                                <i className="ki-outline ki-arrow-down fs-5 text-danger ms-n1" />
                                0.4%
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="separator separator-dashed my-3" />
                        <div className="d-flex align-items-center">
                          <div className="symbol symbol-30px me-5">
                            <span className="symbol-label">
                              <i className="ki-outline ki-abstract-25 fs-3 text-gray-600" />
                            </span>
                          </div>
                          <div className="d-flex align-items-center flex-stack flex-wrap d-grid gap-1 flex-row-fluid">
                            <div className="me-5">
                              <a
                                href="#"
                                className="text-gray-800 fw-bold text-hover-primary fs-6"
                              >
                                Other
                              </a>
                              <span className="text-gray-500 fw-semibold fs-7 d-block text-start ps-0">
                                Many Sources
                              </span>
                            </div>
                            <div className="d-flex align-items-center">
                              <span className="text-gray-800 fw-bold fs-4 me-3">
                                79,458
                              </span>
                              <span className="badge badge-light-success fs-base">
                                <i className="ki-outline ki-arrow-up fs-5 text-success ms-n1" />
                                8.3%
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
