import { NavLink } from "react-router-dom";
import { RoutesPaths } from "../../Router/constant";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserAction } from "../../Action/user";
import { backendUrl } from "../../constants";
import moment from "moment";
import useDebounce from "../../Services/debounse";

export default function User() {
  const { userList, count } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 500); // 500ms debounce delay
  const recordsPerPage = 10;
  const totalPages = Math.ceil(count / recordsPerPage);

  useEffect(() => {
    dispatch(getUserAction(currentPage, recordsPerPage, debouncedSearchQuery));
  }, [dispatch, currentPage, debouncedSearchQuery]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleError = (event) => {
    event.target.src = "assets/img/dummy-image-square.jpg"; // fallback image
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const generatePageNumbers = () => {
    const pageNumbers = [];
    const maxPageNumbers = 5; // Adjust this to show more/less page numbers

    if (totalPages <= maxPageNumbers) {
      for (let i = 0; i < totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      const halfPageNumbers = Math.floor(maxPageNumbers / 2);
      let startPage = Math.max(currentPage - halfPageNumbers, 0);
      let endPage = Math.min(startPage + maxPageNumbers - 1, totalPages - 1);

      if (currentPage > totalPages - halfPageNumbers) {
        startPage = totalPages - maxPageNumbers;
      }

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }
    }

    return pageNumbers;
  };

  const getJoinedDate = (date) => {
    return moment(date).format("DD MMM YYYY hh:mm a");
  };

  return (
    <>
      {/*begin::Main*/}
      <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
        {/*begin::Content wrapper*/}
        <div className="d-flex flex-column flex-column-fluid">
          {/*begin::Toolbar*/}
          <div id="kt_app_toolbar" className="app-toolbar pt-7 pt-lg-10">
            {/*begin::Toolbar container*/}
            <div
              id="kt_app_toolbar_container"
              className="app-container container-fluid d-flex align-items-stretch"
            >
              {/*begin::Toolbar wrapper*/}
              <div className="app-toolbar-wrapper d-flex flex-stack flex-wrap gap-4 w-100">
                {/*begin::Page title*/}
                <div className="page-title d-flex flex-column justify-content-center gap-1 me-3">
                  {/*begin::Title*/}
                  <h1 className="page-heading d-flex flex-column justify-content-center text-gray-900 fw-bold fs-3 m-0">
                    USERS
                  </h1>
                  {/*end::Title*/}
                  {/*begin::Breadcrumb*/}
                  <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0">
                    {/*begin::Item*/}
                    <li className="breadcrumb-item text-muted">
                      {count} USERS
                    </li>
                    {/*end::Item*/}
                  </ul>
                  {/*end::Breadcrumb*/}
                </div>
                {/*end::Page title*/}
              </div>
              {/*end::Toolbar wrapper*/}
            </div>
            {/*end::Toolbar container*/}
          </div>
          {/*end::Toolbar*/}
          {/*begin::Content*/}
          <div id="kt_app_content" className="app-content flex-column-fluid">
            {/*begin::Content container*/}
            <div
              id="kt_app_content_container"
              className="app-container container-fluid"
            >
              {/*begin::Card*/}
              <div className="card">
                {/*begin::Card header*/}
                <div className="card-header border-0 pt-6">
                  {/*begin::Card title*/}
                  <div className="card-title">
                    {/*begin::Search*/}
                    <div className="d-flex align-items-center position-relative my-1">
                      <i className="ki-outline ki-magnifier fs-3 position-absolute ms-5" />
                      <input
                        type="text"
                        data-kt-user-table-filter="search"
                        className="form-control form-control-solid w-250px ps-13"
                        placeholder="Search user"
                        value={searchQuery}
                        onChange={handleSearchChange}
                      />
                    </div>
                    {/*end::Search*/}
                  </div>
                  {/*begin::Card title*/}
                </div>
                {/*end::Card header*/}
                {/*begin::Card body*/}
                <div className="card-body py-4">
                  {/*begin::Table*/}
                  <table
                    className="table align-middle table-row-dashed fs-6 gy-5"
                    id="kt_table_users"
                  >
                    <thead>
                      <tr className="text-start text-muted fw-bold fs-7 text-uppercase gs-0">
                        <th className="min-w-125px">User</th>
                        <th className="min-w-125px">Contact number</th>
                        <th className="min-w-125px">Joined Date</th>
                        <th className="text-end min-w-100px">Actions</th>
                      </tr>
                    </thead>
                    <tbody className="text-gray-600 fw-semibold">
                      {userList?.length > 0 ? (
                        userList.map((user, index) => (
                          <tr key={index}>
                            <td className="d-flex align-items-center">
                              {/*begin:: Avatar */}
                              <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                                <NavLink
                                  to={RoutesPaths.OVERVIEW_USER_MANAGER}
                                  state={{ user: user }}
                                >
                                  <div className="symbol-label">
                                    <img
                                      className="w-100"
                                      src={backendUrl + user.profilePic}
                                      alt=""
                                      onError={handleError} // handle the error event
                                    />
                                  </div>
                                </NavLink>
                              </div>
                              {/*end::Avatar*/}
                              {/*begin::User details*/}
                              <div className="d-flex flex-column">
                                <NavLink
                                  to={RoutesPaths.OVERVIEW_USER_MANAGER}
                                  state={{ user: user }}
                                  className="text-gray-800 text-hover-primary mb-1"
                                >
                                  {user.fullName}
                                </NavLink>
                                <span>{user.email}</span>
                              </div>
                              {/*begin::User details*/}
                            </td>
                            <td>
                              {user.countryCode} {user.mobileNumber}
                            </td>
                            <td>{getJoinedDate(user.createdAt)}</td>
                            <td className="text-end">
                              <NavLink
                                to={RoutesPaths.OVERVIEW_USER_MANAGER}
                                state={{ user: user }}
                                className="btn btn-light btn-active-light-primary btn-flex btn-center btn-sm"
                                data-kt-menu-trigger="click"
                                data-kt-menu-placement="bottom-end"
                              >
                                View Profile
                              </NavLink>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="4" className="text-center">
                            No users available.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  {/*end::Table*/}

                  <div className="d-flex justify-content-center">
                    <button
                      className="btn btn-primary"
                      onClick={() => handlePageChange(0)}
                      disabled={currentPage === 0}
                    >
                      <svg
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-chevron-double-left"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M11.354 1.646a.5.5 0 0 1 0 .708L6.707 7l4.647 4.646a.5.5 0 0 1-.708.708l-5-5a.5.5 0 0 1 0-.708l5-5a.5.5 0 0 1 .708 0zM7.354 1.646a.5.5 0 0 1 0 .708L2.707 7l4.647 4.646a.5.5 0 0 1-.708.708l-5-5a.5.5 0 0 1 0-.708l5-5a.5.5 0 0 1 .708 0z"
                        />
                      </svg>
                    </button>
                    <button
                      className="btn btn-primary mx-2"
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 0}
                    >
                      <svg
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-chevron-left"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M11.354 1.646a.5.5 0 0 1 0 .708L6.707 7l4.647 4.646a.5.5 0 0 1-.708.708l-5-5a.5.5 0 0 1 0-.708l5-5a.5.5 0 0 1 .708 0z"
                        />
                      </svg>
                    </button>

                    {generatePageNumbers().map((page, index) => (
                      <button
                        key={index}
                        className={`btn btn-light btn-active-light-primary mx-1 ${
                          page === currentPage ? "active" : ""
                        }`}
                        onClick={() => handlePageChange(page)}
                      >
                        {page + 1}
                      </button>
                    ))}

                    <button
                      className="btn btn-primary mx-2"
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage === totalPages - 1}
                    >
                      <svg
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-chevron-right"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.646 1.646a.5.5 0 0 1 .708 0l5 5a.5.5 0 0 1 0 .708l-5 5a.5.5 0 0 1-.708-.708L9.293 7 4.646 2.354a.5.5 0 0 1 0-.708z"
                        />
                      </svg>
                    </button>
                    <button
                      className="btn btn-primary"
                      onClick={() => handlePageChange(totalPages - 1)}
                      disabled={currentPage === totalPages - 1}
                    >
                      <svg
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-chevron-double-right"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.646 1.646a.5.5 0 0 1 .708 0l5 5a.5.5 0 0 1 0 .708l-5 5a.5.5 0 0 1-.708-.708L9.293 7 4.646 2.354a.5.5 0 0 1 0-.708zm4 0a.5.5 0 0 1 .708 0l5 5a.5.5 0 0 1 0 .708l-5 5a.5.5 0 0 1-.708-.708L13.293 7 8.646 2.354a.5.5 0 0 1 0-.708z"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                {/*end::Card body*/}
              </div>
              {/*end::Card*/}
            </div>
            {/*end::Content container*/}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Content wrapper*/}
      </div>
      {/*end:::Main*/}
    </>
  );
}
