export const RoutesPaths = {
  HOME: "/",
  LOGIN: "/login",
  DASHBOARD: "/dashboard",
  USER_MANAGER: "/user-manager",
  OVERVIEW_USER_MANAGER: "/user-manager/overview-user-manager",
  SERVICE_PROVIDER: "/service-provider",
  OVERVIEW_SERVICE_PROVIDER: "/service-provider/overview-service-provider",
  MANAGE_SERVICE_PROVIDER: "/service-provider/manage-service-provider",
  SERVICES: "/services",
  ADDEDITSERVICES: "/services/edit-services",
  REVENUE_TRACKING: "/revenue-tracking",
  NOTIFICATION_CENTER: "/notification-center",
  COUPON: "/coupons",
  CREATECOUPON: "/coupons/create-coupon",
  PROFILE: "/profile",
  EDITPROFILE: "/profile/edit-profile",
  CATEGORY: "/category",
  EDITCATEGORY: "/category/edit-category",
  BANNERS: "/banners",
  BANNER: "/banner",
  QUESTION: "/question",
  QUESTIONS: "/edit-question"
};
